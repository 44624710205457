<template>
  <div>
    <router-link class="come-back" to="/admin/partners">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">{{ $route.query.type === 'edit' ? 'Редактирование Партнера' : 'Создание Партнера' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="createParthers" ref="createParthers">
      <div>
        <!-- <div class="content-sb">
          <p>Страна<span>*</span></p>
          <select  @change="changeCountry" required class="input blue"  name="country">
            <option v-if="this.$route.query.type === 'create'" disabled selected>Выберите кем утвержден</option>
            <option
              v-for="item in $store.getters.getCountry"
              :selected="form.country === item.title"
              :key="`getCountry${item.id}`"
              :value="item.id"
            >{{ item.title }}</option>
          </select>
        </div> -->
        <div class="content-sb">
          <p>Дата подписания<span>*</span></p>
          <input :required="$route.query.type === 'edit' ? false : true"
            placeholder="Введите дату" name="signing" type="date" v-model="form.signing" class="input blue">
        </div>

        <div class="content-sb" v-if="$route.query.type === 'edit'">
          <p>Старый флаг<span>*</span></p>
          <img :src="$store.getters.getUrlApi + form.flagUrl">
        </div>

        <div class="content-sb">
          <p>Флаг<span>*</span></p>
          <input :required="$route.query.type === 'edit' ? false : true"  type="file" name="flag"  class="input blue">
        </div>

        <div v-if="$route.query.type === 'edit'" class="content-sb">
          <p>Старый документ</p>
          <a target="_blank" :href="`${$store.getters.getUrlApi}${form.document.url}`">Ссылка</a>
        </div>

        <div class="content-sb">
          <p>Докумет<span>*</span></p>
          <input :required="$route.query.type === 'edit' ? false : true"  type="file" name="document"  class="input blue">
        </div>

        <div class="content-sb">
          <p>Название документа</p>
          <input placeholder="Введите название документа" name="documentTitle" type="text" v-model="form.documentTitle" class="input blue">
        </div>

      </div>
      <footer class="content-sb btns-create-form">
        <router-link to="/admin/partners" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" :value="$route.query.type === 'edit' ? 'Изменить' : 'Сохранить'"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'
export default {
  name: 'createPartners',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailPartners : {
        country: '',
        documentTitle: ''
      }
    }
  },
  methods: {
    createParthers () {
      const formData = new FormData(this.$refs.createParthers)
      if (this.$route.query.type === 'edit') {
        this.$store.dispatch('editPartners', { data: formData, id: this.form.id })
      } else {
        formData.append('country', this.$store.getters.getCountry[0].id)
        this.$store.dispatch('createPartners', formData)
      }
    }
    // changeCountry (e) {
    //   this.form.country = e.target.value
    // }
  },
  mounted () {
    this.$store.dispatch('setCountry')
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailPartners === null) {
      this.$router.push('/admin/partners')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
img{
  width: 15rem;
}
</style>
